<template>
  <div class="hidden self-end lg:absolute lg:right-10 lg:top-[5.55rem] lg:block xl:right-28">
    <div class="max-w-xs lg:max-w-[22.75rem]">
      <div class="mb-4 aspect-3/2 overflow-hidden rounded-t-2xl">
        <NuxtLink
          :to="
            data.blocks[0].internalLink !== null
              ? localePathByType(
                  data?.blocks[0].internalLink!.__typename as string,
                  data?.blocks[0].internalLink!.slug as string,
                  data?.blocks[0].internalLink!.__typename === ('ArticleRecord' as string) &&
                    data?.blocks[0].internalLink!.category.slug
                )
              : data.blocks[0].externalLink
          "
          @click="onClick"
        >
          <Picture class="aspect-3/2" imgClass="w-full" loading="lazy" :data="data?.image!" />
        </NuxtLink>
      </div>
      <div v-if="data.title" class="text-heading-20-medium">
        {{ data.title }}
      </div>
      <div v-if="Array.isArray(data.blocks) && data.blocks.length">
        <Button
          :label="data.blocks[0].label"
          :to="
            data.blocks[0].internalLink !== null
              ? localePathByType(
                  data?.blocks[0].internalLink!.__typename as string,
                  data?.blocks[0].internalLink!.slug as string,
                  data?.blocks[0].internalLink!.__typename === ('ArticleRecord' as string) &&
                    data?.blocks[0].internalLink!.category.slug
                )
              : data.blocks[0].externalLink
          "
          :target="data.blocks[0].externalLink ? '_blank' : undefined"
          class="mt-4 !p-0 !text-heading-20-medium text-purple"
          variant="link"
          :icon="{
            name: 'chevron-right-general',
            color: colors.purple.DEFAULT,
            className: 'w-4px h-4px'
          }"
          @click="onClick"
        />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import colors from '#tailwind-config/theme/colors'
import { MenuCtaRecord } from '~/types/generated'
const { localePathByType } = useLocalePathByType()

const { menuHide } = useMenu()

defineProps({
  data: {
    type: Object as PropType<MenuCtaRecord>,
    default: () => {}
  }
})

function onClick() {
  menuHide()
}
</script>
